@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* font-family: 'Inter'; */
  /* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  font-family:Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}
ul {
  list-style: inside;
}

.ace_scroller {
  background-color: #a3a0a1;
  color: #c7254e;
}
.ace-idle-fingers .ace_gutter {
  background-color: #a3a0a1;
}
.jodit-source {
  background-color: #a3a0a1;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.jodit-popup__content {
  display: none;
}
/* :root .jodit-popup__content {
  display: none !important;
} */


.hideJoditPopup .jodit-popup__content {
  display: none !important;
}


.jodit-popup__content {
  display: block !important;
}


/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #35363a;
  font-size: 12px;
  line-height: 14.52px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tblstatus {
  min-width: 6em;
  height: 20px;
  padding: 0rem 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  outline: none;
  text-align: center;
}
.status-select {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 6px;
  padding: 8px;
}
@layer base {
  :root {
    --accent: #fa9b31;
    --light-accent: #fffae8;
    --main-red: #e93837;
  }
  img {
    display:inline;
  }
  audio {
    display:inline;
  }
 
}

/* Login */
@media screen and (max-width: 1300px) {
  .login-main {
    width: 1000px;
    height: 570px !important;
  }
  .login-r {
    margin-top: 0 !important;
    height: 500px !important;
  }
}

.rectangle {
  color: #35363a;
  /* display: flex; */
  display: inline-flex;
  /* width: fit-content !important; */
  /* height: fit-content !important; */
  /* width: fit-content;
  height: fit-content; */
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 0 5px;
  /* pointer-events: none; */
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid #ececec;
  background: #fff;
  cursor: auto;
}

.custom-focused-input input:focus {
  border-color: #de2128 !important;
  width: 100%;
}


/* Tooltip editor */

.tooltipEditor {
  position: relative;
  display: inline-block;

}

.tooltipEditor .tooltip-text {
  visibility: hidden;
  font-size: 15px;
  width: 120px;
  background-color: #fa9b31;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipEditor:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}





/*.jodit-container .jodit-workplace .jodit-wysiwyg {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.answer-placeholder:empty:not(:focus):before {
  content: attr(data-text);
  color: #999999;
}*/

.editor-over-limit .jodit-workplace .jodit-wysiwyg {
  background-color: #fbe3e3;
}

.editor-container .jodit_toolbar_box {
  display: none;
}

.editor-container.focused .jodit_toolbar_box {
  display: block;
}

.ck-editor__editable_inline ul, ol {
  margin-left: 10px;
  padding-left: 10px;
  list-style-type: auto; 
}

.ck-editor__editable_inline ol {
  list-style-type: decimal; 
}

.editor-over-limit .ck-editor__editable_inline {
  background-color: #fbe3e3 !important;
}

.custom_jodit,
.custom_jodit--hidden {
  width: 100% !important;
}

.custom_jodit .jodit-container,
.custom_jodit--hidden .jodit-container {
  border: none !important;
}

.custom_jodit .jodit-wysiwyg,
.custom_jodit--wrapper .jodit-container,
.custom_jodit .jodit-workplace,
.custom_jodit--hidden .jodit-wysiwyg,
.custom_jodit--hidden .jodit-workplace {
  min-height: 45px !important;
  min-width: 100% !important;
  border: none !important;
  background-color: transparent !important;
}

.custom_jodit .jodit-status-bar,
.custom_jodit--hidden .jodit-status-bar {
  display: none;
}

.custom_jodit--wrapper .jodit-react-container,
.custom_jodit--hidden .jodit-react-container {
  flex: 1;
}

.custom_jodit--wrapper .jodit-container:not(.jodit_inline) {
  border: none !important;
  background-color: transparent !important;
}

.custom_jodit--hidden .jodit-toolbar__box {
  display: none;
}

.wrapper {
  border: 1px solid #d9d9d9;
  margin-top: -50px;
  background-color: #fff;
  padding: 30px;
}

.jodit-popup {
  left: 45% !important;
  top: 45% !important;
}

.ck-content {
  min-height: 150px;
}

.answertblclass table {
  border-top: 1px solid grey;
  border-left: 1px solid grey;
  border-collapse: collapse;
}
.answertblclass {
  display: flex;
  justify-content: center;
}

.answertblclass tr td {
  border-right: grey 1px solid;
  border-bottom: grey 1px solid;
  padding: 0.4em;
}

.question-preview table {
  border: #888 1px solid;
}

.question-preview tr td {
  border-right: grey 1px solid;
  border-bottom: grey 1px solid;
  padding: 0.4em;
}

.jodit-wysiwyg img{
  display: inline !important;
}

.display-content table, #radio table, #block table, #checkbox table, #cms table {
  border: #888 1px solid;
}

.display-content tr td, #radio tr td, #block tr td, #checkbox tr td, #cms tr td  {
  border-right: grey 1px solid;
  border-bottom: grey 1px solid;
  padding: 0.4em;
  height:40px;
}

.bg-light-accent table {
  border: #888 1px solid;
} 

.bg-light-accent tr td {
  border-right: grey 1px solid;
  border-bottom: grey 1px solid;
  padding: 0.4em;
  height:40px;
}
/*
#radio table {
  border: #888 1px solid;
}

#radio tr td {
  border-right: grey 1px solid;
  border-bottom: grey 1px solid;
  padding: 0.4em;
  height:40px;
}*/

.dropdown-content{
  z-index:1000 !important;
}